import React from "react";
import I18nContext from "../lib/i18n-context";

const Ideas = () => {
  const { locale } = React.useContext(I18nContext);

  const data = locale.ideas;

  return (
    <section
      className="w-full text-darkblue bg-fixed bg-cover bg-center"
      style={{ backgroundImage: `url(${"/assets/ideas-bg-cream.svg"})` }}
    >
      <div className="container max-w-screen-xl flex flex-col items-center justify-center space-y-10 sm:space-x-10 lg:space-x-16 sm:space-y-0 sm:flex-row py-16 lg:py-24 px-10 xl:px-12">
        <div className="flex-1">
          <img
            data-aos="fade-right"
            data-aos-offset="300"
            data-aos-duration="1900"
            className="w-full"
            src="/assets/ideas-design.png"
            alt="display design of ideas section"
          />
        </div>
        <div className="flex-1">
          <div className="space-y-6 sm:w-72 xl:w-80">
            <h2 className="text-3xl sm:text-2xl md:text-3xl lg:text-4xl xl:text-5xl font-arima">
              {data.title}
            </h2>
            <p className="italic font-poppins sm:text-sm lg:text-lg xl:text-xl">
              {data.description}
            </p>
            <div className="pt-2">
              <a href="#ideas">
                <svg
                  className="h-3 pl-2"
                  xmlns="http://www.w3.org/2000/svg"
                  width="38.133"
                  height="21.895"
                  viewBox="0 0 38.133 21.895"
                >
                  <path
                    id="Path_752"
                    data-name="Path 752"
                    d="M809.7,852.977l17.652,17.652L845,852.977"
                    transform="translate(-808.281 -851.563)"
                    fill="none"
                    stroke="#1e3d49"
                    strokeWidth="4"
                  />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Ideas;

const convertVideo = (video, autoPlay = true) => {
    const vimeo = /(?:http?s?:\/\/)?(?:www\.)?(?:vimeo\.com)\/?(.+)/g
    const youtube = /(?:http?s?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/g
    if (vimeo.test(video)) {
        const autoPlayVimeo = autoPlay ? '?autoplay=1&muted=1&loop=1' : ''
        const replacement = '//player.vimeo.com/video/$1' + autoPlayVimeo
        video = video.replace(vimeo, replacement)
    }
    if (youtube.test(video)) {
        const autoPlayYoutube = autoPlay ? '?autoplay=1' : ''
        const replacement = 'https://www.youtube.com/embed/$1' + autoPlayYoutube
        video = video.replace(youtube, replacement)
    }
    return video
}

export default convertVideo

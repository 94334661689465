import React from "react";
import I18nContext from "../lib/i18n-context";

const ContactUs = () => {
  const { locale, currentLocale } = React.useContext(I18nContext);

  const data = locale.contactUs;

  return (
    <section
      id="contact-us"
      className="w-full container max-w-screen-xl pt-20 pb-10 sm:pb-20 px-10 md:px-14 lg:px-20 text-darkblue"
    >
      <div>
        <div className="space-y-10">
          <h2 className="text-3xl lg:text-4xl pb-2 pr-3 border-b-2 font-arima">
            {data.title}
          </h2>
          <div className="pl-3 leading-7 sm:flex sm:items-center sm:justify-center space-y-10 sm:space-y-0 mx-auto ">
            <div className="flex-1 space-y-6 flex flex-col sm:items-center">
              <ul className="font-poppins english-font leading-6">
                <div className={`font-arima font-light pb-6 ${currentLocale === 'en' ? 'text-3xl' : 'text-[32px]'}`}>
                  {data.uk}
                </div>
                <div className="text-md font-light">
                  <li>QIVA Global</li>
                  <li>Westgate Chambers</li>
                  <li>Staple Gardens</li>
                  <li>Winchester</li>
                  <li>SO23 8SR</li>
                </div>
                <div className="pt-6 text-md font-light">
                  <li>
                    <a
                      className="hover:underline english-font font-poppins"
                      href="tel:01962435011"
                    >
                      Tel: 01962 398068
                    </a>
                  </li>
                  <li>
                    <a
                      className="hover:underline english-font font-poppins"
                      href="mailto:info@qivaglobal.com"
                    >
                      Email: info@qivaglobal.com
                    </a>
                  </li>
                </div>
                <div className="font-poppins english-font leading-7 text-md font-light pt-5">
                  <li>Company registered in England: 10160546</li>
                  <li>VAT Registration: 333967475</li>
                </div>
              </ul>
            </div>
            <div className="flex-1">
              <img src="/assets/uk-map.png" alt="map of UK" />
            </div>
          </div>
          <div className="pl-3 leading-7 sm:flex sm:items-center space-y-10 sm:space-y-0">
            <div className="flex-1 space-y-6">
              <ul>
                <li className={`font-arima font-light pb-2 ${currentLocale === 'en' ? 'text-3xl' : 'text-[32px]'}`}>{data.asia}</li>
              </ul>
              <ul className="font-poppins english-font leading-6 space-y-6 lg:space-y-8">
                <li className="space-y-2">
                  <h3 className={`font-arima ${currentLocale === 'en' ? 'text-lg' : 'text-xl'}`}>{data.china}:</h3>
                  <p className="text-sm font-light font-poppins">
                    山东省威海市文登区天福办宝宇凯旋商贸城四楼
                  </p>
                  <p className="text-sm font-light">TEL: 18769136632</p>
                </li>
                <li className="space-y-2">
                  <h3 className={`font-arima ${currentLocale === 'en' ? 'text-lg' : 'text-xl'}`}>{data.japan}:</h3>
                  <p className="text-sm font-light font-poppins">
                    郵便番号：<span className="english-font font-poppins">5560014</span>
                    <br />
                    大阪府大阪市浪速区大国町<span className="english-font font-poppins">2-15-18</span>
                  </p>
                  <p className="text-sm font-light">TEL: 06-4963-3897</p>
                </li>
              </ul>
            </div>
            <div className="flex-1 sm:order-first">
              <img src="/assets/asia-map.png" alt="map of Asia" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUs;

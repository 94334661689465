import React from "react";
import I18nContext from "../lib/i18n-context";

const CallToAction = () => {
  const { locale, currentLocale } = React.useContext(I18nContext);

  const data = locale.callToAction;

  return (
    <section className="bg-darkblue pb-10 border-b-2 border-white">
      <div className="container max-w-screen-xl px-10 py-18 lg:px-16 text-white space-y-8 md:space-y-0 md:flex md:space-x-6 lg:space-x-16">
        <div className="md:w-1/2 flex flex-col justify-center space-y-6">
          <h3 className={`text-xl md:text-3xl lg:text-5xl lg:leading-11 font-arima max-w-lg ${currentLocale === 'cn' ? 'xl:whitespace-nowrap z-[1]' : ''}`}>
            {data.title}
          </h3>
          <p className="font-poppins text-base lg:text-lg">
            {data.description}
          </p>
          <div>
            <a
              href="https://www.linkedin.com/company/qiva-global/"
              target="_blank"
              rel="noopener noreferrer"
              className="font-poppins italic text-sm font-light rounded-xl border px-8 sm:px-6 md:px-8 lg:px-12 py-1 border-white hover:bg-gray-500 hover:text-white"
            >
              {data.buttonTitle}
            </a>
          </div>
        </div>
        <div className="md:w-1/2">
          <img
            src="/assets/cta.png"
            alt="Case study graphic"
            className="w-full h-auto"
          />
        </div>
      </div>
    </section>
  );
};

export default CallToAction;

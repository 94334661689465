import React, { useEffect, useState } from "react";
import "../styles/scss/style.scss";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Footer from "../components/Footer";
import AOS from "aos";
import "aos/dist/aos.css";
import $ from "jquery";
import Seo from "../components/Seo";
import Modal from "../components/Modal";
import HomeBanner from "../components/HomeBanner";
import AboutUs from "../components/AboutUs";
import OurWork from "../components/OurWork";
import WhyUs from "../components/WhyUs";
import OurModel from "../components/OurModel";
import OurClients from "../components/OurClients";
import Ideas from "../components/Ideas";
import LatestBlogs from "../components/LatestBlogs";
import Testimonials from "../components/Testimonials";
import FeaturedIn from "../components/FeaturedIn";
import ContactUs from "../components/ContactUs";
import CallToAction from "../components/CallToAction";

const Home = ({
  data: {
    site,
    allMarkdownRemark: { edges },
  },
}) => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();

    $(document).ready(function() {
      $("a").on("click", function(event) {
        if (this.hash !== "") {
          event.preventDefault();

          let hash = this.hash;

          $("html, body").animate(
            {
              scrollTop: $(hash).offset().top,
            },
            800,
            function() {
              window.location.hash = hash;
            }
          );
        }
      });
    });
  }, []);

  const [showModal, setShowModal] = useState(false);

  const videoRef = React.useRef(null);
  const fullVideoRef = React.useRef(null);

  return (
    <Layout>
      <Seo title={"QIVA Global"} description={site.siteMetadata.description} />
      {showModal && (
        <Modal
          show={showModal}
          setShowModal={setShowModal}
          bannerVideoRef={videoRef}
          videoRef={fullVideoRef}
        />
      )}
      <HomeBanner setShowModal={setShowModal} videoRef={videoRef} />
      <main id="main">
        <AboutUs />
        <OurWork />
        <WhyUs />
        <OurModel />
        <OurClients />
        <Ideas />
        <LatestBlogs edges={edges} />
        <Testimonials />
        <FeaturedIn />
        <ContactUs />
        <CallToAction />
      </main>
      <Footer />
      <script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
      <script src="https://cdnjs.cloudflare.com/ajax/libs/magnific-popup.js/1.1.0/jquery.magnific-popup.min.js"></script>
    </Layout>
  );
};

export default Home;

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMarkdownRemark(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            thumbnail
          }
        }
      }
    }
  }
`;

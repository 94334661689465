import React from 'react'
import { Link } from 'gatsby'

const PostLink = ({ post }) => (
    <article id="blog-article" className="space-y-3">
        <Link to={post.frontmatter.path}>
            {!!post.frontmatter.thumbnail && (
                <img
                    className="rounded-lg object-cover h-48 w-full"
                    src={post.frontmatter.thumbnail}
                    alt={post.frontmatter.title + '- Featured Shot'}
                />
            )}
        </Link>
        <div className="flex flex-col justify-between h-24">
            <header>
                <h2 className="pl-2 font-arima english-font hover:underline">
                    <Link to={post.frontmatter.path}>{post.frontmatter.title}</Link>
                </h2>
            </header>
            <div className="flex items-center pl-2 font-poppins english-font text-xs space-x-3 font-light">
                <p className="italic hover:underline">
                    <Link to={post.frontmatter.path}>Read More</Link>
                </p>
                <svg
                    className="h-2"
                    xmlns="http://www.w3.org/2000/svg"
                    width="11.794"
                    height="11.428"
                    viewBox="0 0 11.794 11.428"
                >
                    <g
                        id="Polygon_52"
                        data-name="Polygon 52"
                        transform="translate(7.794 11.428) rotate(-150)"
                        fill="none"
                    >
                        <path d="M4.5,0,9,8H0Z" stroke="none" />
                        <path
                            d="M 4.5 2.039735317230225 L 1.709851264953613 7 L 7.290148735046387 7 L 4.5 2.039735317230225 M 4.5 0 L 9 8 L 0 8 L 4.5 0 Z"
                            stroke="none"
                            fill="#1e3d49"
                        />
                    </g>
                </svg>
            </div>
        </div>
    </article>
)
export default PostLink

import React from "react";
import Slider from "../components/Slider";

const Testimonials = () => {
  return (
    <section
      className="w-full bg-cover text-darkblue bg-center flex flex-col items-center justify-center py-20"
      style={{
        backgroundImage: `url(${"/assets/animated.svg"})`,
        height: "80vh",
      }}
    >
      <Slider />
    </section>
  );
};

export default Testimonials;

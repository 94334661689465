import React from "react";
import { Link } from "gatsby";
import I18nContext from "../lib/i18n-context";

const AboutUs = () => {
  // i18n
  const i18nContext = React.useContext(I18nContext);
  const { locale } = i18nContext;

  const data = locale.aboutUs;

  return (
    <section className="w-full text-darkblue">
      <div className="container max-w-screen-xl flex flex-col items-center justify-center sm:flex-row py-12 lg:py-20 px-10 lg:px-12 space-y-10 sm:space-y-0">
        <div className="flex-1 sm:order-last">
          <img
            data-aos="fade-left"
            data-aos-offset="200"
            data-aos-duration="1900"
            className="w-full"
            src="/assets/asian-map--about-us.jpg"
            alt="display of our model"
          />
        </div>
        <div className="flex-1 w-full space-y-6">
          <div className="text-left space-y-5 lg:space-y-7 font-arima px-4 sm:px-0 sm:pr-6 md:pr-20 lg:pr-28">
            <h2 className="text-3xl sm:text-2xl md:text-3xl lg:text-5xl">
              {data.title}
            </h2>
            {data.subTitle}
            <p className="font-poppins text-sm sm:text-xs md:text-sm lg:text-md xl:text-lg">
              {data.description}
            </p>
          </div>
          <div className="pl-6 sm:pl-0">
            <button
              type="button"
              className="font-poppins italic text-sm font-light rounded-xl border px-8 sm:px-6 md:px-8 lg:px-12 py-1 border-darkblue hover:bg-gray-500 hover:text-white"
            >
              <Link to="/about-us">{data.buttonTitle}</Link>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;

import * as React from 'react'
import { Dialog, Transition } from '@headlessui/react'
import convertVideo from '../lib/convert-video'
import I18nContext from "../lib/i18n-context";

const Cross = ({ classes }) => {
    return (
        <svg
            className={classes}
            width="19"
            height="19"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.42492 15.7885C0.839132 16.3743 0.839132 17.3241 1.42492 17.9098C2.0107 18.4956 2.96045 18.4956 3.54624 17.9098L9.7207 11.7354L15.9097 17.9244C16.4955 18.5101 17.4452 18.5101 18.031 17.9244C18.6168 17.3386 18.6168 16.3888 18.031 15.803L11.842 9.61406L18.3955 3.0606C18.9813 2.47481 18.9813 1.52507 18.3955 0.93928C17.8097 0.353493 16.8599 0.353494 16.2742 0.93928L9.7207 7.49274L3.18175 0.953788C2.59597 0.368001 1.64622 0.368001 1.06043 0.953788C0.474647 1.53957 0.474647 2.48932 1.06043 3.07511L7.59938 9.61406L1.42492 15.7885Z"
                fill="white"
            />
        </svg>
    )
}

const Modal = ({ data, showModal = true, setShowModal, bannerVideoRef, videoRef }) => {
  const { currentLocale } = React.useContext(I18nContext);

    const localeVideo = currentLocale === 'en' ? 'https://vimeo.com/763430980' : 'https://vimeo.com/766942780'
    const videoUrl = data && data.url ? data.url : localeVideo

    return (
        <Transition appear show={showModal} as={React.Fragment}>
            <Dialog
                as="div"
                className="fixed inset-0 z-50 overflow-y-auto"
                onClose={() => {
                    setShowModal(false)
                    if (bannerVideoRef.current) {
                        bannerVideoRef.current.play()
                    }
                }}
            >
                <div className="min-h-screen px-4 text-center">
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 cursor-pointer bg-black bg-opacity-80">
                            <div className="flex w-full justify-end p-5 sm:p-10">
                                <button
                                    onClick={() => {
                                        setShowModal(false)
                                        if (bannerVideoRef.current) {
                                            bannerVideoRef.current.play()
                                        }
                                    }}
                                    aria-labelledby="button-close-label"
                                >
                                    <span className="sr-only" id="button-close-label">
                                        Close
                                    </span>
                                    <Cross classes="fill-white w-5 h-5 sm:w-7 sm:h-7 transition duration-300 ease-in hover:opacity-30" />
                                </button>
                            </div>
                        </Dialog.Overlay>
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="inline-block h-screen align-middle" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={React.Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div
                            style={{ maxWidth: '80%' }}
                            className="inline-block w-full transform overflow-hidden rounded-md text-left align-middle shadow-xl transition-all"
                        >
                            <div
                                style={{ paddingBottom: '56.27%' }}
                                className="relative h-0 bg-white"
                            >
                                {videoUrl && (
                                    <iframe
                                        className="absolute top-0 left-0 h-full w-full"
                                        src={convertVideo(videoUrl)}
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                        frameBorder="0"
                                    ></iframe>
                                )}
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    )
}

export default Modal

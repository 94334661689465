import React from "react";
import I18nContext from "../lib/i18n-context";

const OurClients = () => {
  const { locale } = React.useContext(I18nContext);

  const data = locale.ourClients;

  return (
    <section className="w-full bg-darkblue text-white">
      <div className="container max-w-screen-xl pt-14 md:pt-20 px-10 md:px-14 lg:px-20 sm:pb-6">
        <h2 className="font-arima text-3xl w-full border-b pb-1 md:text-4xl font-light">
          {data.title}
        </h2>
        <div className="grid place-items-center place-content-center grid-cols-2 sm:grid-cols-4 gap-10 w-full py-16">
          <div>
            <img src="/assets/perrigo.png" alt="Perrigo's company logo" />
          </div>
          <div>
            <img
              src="/assets/Alliance.png"
              alt="Alliance Health company logo"
            />
          </div>
          <div>
            <img src="/assets/floradix-logo.svg" alt="Floradix company logo" />
          </div>
          <div>
            <img src="/assets/Advent.png" alt="Advent's company logo" />
          </div>
          <div>
            <img src="/assets/Nandos.png" alt="Nandos company logo" />
          </div>
          <div>
            <img src="/assets/grenade-logo.svg" alt="Grenade company logo" />
          </div>
          <div>
            <img src="/assets/ecotone-logo.svg" alt="Ecotone company logo" />
          </div>
          <div>
            <img src="/assets/ceuta-group.svg" alt="Ceuta Group company logo" />
          </div>
          <div>
            <img src="/assets/edgard-cooper.png" alt="Edgard Cooper logo" />
          </div>
          <div>
            <img src="/assets/vvardis.png" alt="vVardis company logo" />
          </div>
          <div>
            <img src="/assets/SIS.png" alt="Science in Sport company logo" />
          </div>
          <div>
            <img src="/assets/Steiner.png" alt="Steiner company logo" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurClients;

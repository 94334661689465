import React from "react";
import PostLink from "../components/post-link";

const LatestBlogs = ({ edges }) => {
  const Posts = edges
    .filter((edge) => !!edge.node.frontmatter.date) // You can filter your posts based on some criteria
    .map((edge) => <PostLink key={edge.node.id} post={edge.node} />)
    .slice(0, 6); // Decide how many to display

  return (
    <section id="ideas" className="w-full bg-lightpink  text-darkblue">
      <div className="container max-w-screen-xl py-20 px-10 md:px-20 lg:px-24 xl:px-40">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {Posts}
        </div>
      </div>
    </section>
  );
};

export default LatestBlogs;

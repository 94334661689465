import React from "react";
import { Link } from "gatsby";
import I18nContext from "../lib/i18n-context";

const OurWorkCard = (props) => {
  const i18nContext = React.useContext(I18nContext);
  const { locale, currentLocale } = i18nContext;

  const data = locale.ourWork;

  return (
    <div
      data-aos={props.animation}
      data-aos-duration={props.duration}
      data-aos-offset="300"
      data-aos-easing="ease-in-sine"
      className={`w-full ${props.border}`}
    >
      <Link to={props.path}>
        <div className="relative flex justify-center items-center">
          <img
            src={props.img}
            className="object-contain w-full"
            alt={props.alt}
          />
          <img
            className="absolute top-0 left-0 w-full"
            src={props.shade}
            alt={props.shadeAlt}
          />
          <img
            src={props.logo}
            className={`absolute mx-auto top-4 right-4 bg-white rounded-md ${props.logoClass ? props.logoClass : "w-4/12"
              }`}
            alt={props.logoAlt}
          />
        </div>
        <div className=" bg-white text-darkblue p-4 space-y-4 h-36">
          <div className="flex justify-between">
            <div>
              <h4 className={`font-arima font-medium text-lg md:text-sm ${currentLocale === 'cn' ? 'lg:text-xl' : 'lg:text-lg'}`}>
                {" "}
                {props.title}{" "}
              </h4>
            </div>
            <div>
              <div
                className={`flex items-center space-x-3 md:space-x-1 ${props.class}`}
              >
                <button
                  type="button"
                  className="hover:underline font-light text-xs font-poppins"
                >
                  {" "}
                  {data.buttonTitle}{" "}
                </button>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8.687"
                  height="8.366"
                  viewBox="0 0 11.687 11.366"
                >
                  <path
                    id="Path_754"
                    data-name="Path 754"
                    d="M4.438.036l4.5,8L.063,7.963Z"
                    transform="translate(7.724 11.428) rotate(-150)"
                    fill="#1e3d49"
                  />
                </svg>
              </div>
            </div>
          </div>
          <div className="font-poppins font-light text-sm md:text-xs lg:text-sm">
            {props.desc}
          </div>
          <div
            className={`items-center space-x-3 md:space-x-1 hidden ${props.classTwo}`}
          >
            <button
              type="button"
              className="hover:underline font-light text-xs font-poppins"
            >
              {" "}
              {data.buttonTitle}{" "}
            </button>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="8.687"
              height="8.366"
              viewBox="0 0 11.687 11.366"
            >
              <path
                id="Path_754"
                data-name="Path 754"
                d="M4.438.036l4.5,8L.063,7.963Z"
                transform="translate(7.724 11.428) rotate(-150)"
                fill="#1e3d49"
              />
            </svg>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default OurWorkCard;

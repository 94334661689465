import React from "react";
import I18nContext from "../lib/i18n-context";

const WhyUs = () => {
  const [tab, setTab] = React.useState("brand");

  const i18nContext = React.useContext(I18nContext);
  const { locale } = i18nContext;

  const data = locale.whyUs;

  const whyUsBgImage = () => {
    if (tab === "brand") {
      return "/assets/writing-brand.svg";
    } else if (tab === "operation") {
      return "/assets/writing-operation.svg";
    } else if (tab === "partner") {
      return "/assets/writing-partner.svg";
    }
  };

  const toggleWhyUsBox = () => {
    if (tab === "brand") {
      return (
        <div className="font-poppins space-y-8">
          {data.brand.features.map((item, index) => (
            <div className="space-y-1" key={index}>
              <h4 className="text-xl font-arima">{item.title}</h4>
              <p className="text-sm font-light">{item.description}</p>
            </div>
          ))}
        </div>
      );
    } else if (tab === "operation") {
      return (
        <div className="font-poppins space-y-8">
          {data.operation.features.map((item, index) => (
            <div className="space-y-1" key={index}>
              <h4 className="text-xl font-arima">{item.title}</h4>
              <p className="text-sm font-light">{item.description}</p>
            </div>
          ))}
        </div>
      );
    } else if (tab === "partner") {
      return (
        <div className="font-poppins space-y-8">
          {data.partner.features.map((item, index) => (
            <div className="space-y-1" key={index}>
              <h4 className="text-xl font-arima">{item.title}</h4>
              <p className="text-sm font-light">{item.description}</p>
            </div>
          ))}
        </div>
      );
    }
  };
  
  return (
    <section
      className="w-full text-darkblue bg-fixed bg-cover bg-left "
      style={{ backgroundImage: `url(${"/assets/why-us-bg.svg"})` }}
    >
      <div className="container max-w-screen-xl flex flex-col justify-center lg:space-x-20 space-y-10 lg:space-y-0 lg:flex-row py-16 lg:py-24 px-10 xl:px-12">
        <div className="lg:flex-1">
          <img
            data-aos="fade-right"
            data-aos-offset="500"
            data-aos-duration="2300"
            className="w-full"
            src="/assets/why-us-display.png"
            alt="why us illustrated design"
          />
        </div>
        <div className="lg:flex-1 space-y-10 lg:pt-18">
          <div className="space-y-5">
            <h2 className="font-arima text-3xl md:text-4xl xl:text-5xl">
              {" "}
              {data.title}{" "}
            </h2>
            <h5 className="font-arima text-md sm:text-sm md:text-md lg:text-xl xl:text-2xl">
              {data.subTitle}
            </h5>
          </div>
          <div
            style={{
              backgroundImage: `url(${whyUsBgImage()})`,
              backgroundPosition: "top 50% right -50px",
            }}
            className="sm:flex bg-no-repeat"
          >
            <div className=" sm:flex flex-col space-y-14 sm:w-1/2">
              <div
                className={`pl-3 ${
                  tab === "brand" ? "border-l-2" : "opacity-50"
                }`}
              >
                <button
                  type="button"
                  onClick={(e) =>
                    tab === "brand" ? setTab("") : setTab("brand")
                  }
                >
                  <h3 className={`text-2xl font-arima`}>{data.brand.title}</h3>
                </button>
              </div>
              <div className="sm:hidden">
                {tab === "brand" ? toggleWhyUsBox() : ""}
              </div>
              <div
                className={`pl-3 ${
                  tab === "operation" ? "border-l-2" : "opacity-50"
                }`}
              >
                <button
                  type="button"
                  onClick={(e) =>
                    tab === "operation" ? setTab("") : setTab("operation")
                  }
                >
                  <h3 className={`text-2xl font-arima`}>
                    {data.operation.title}
                  </h3>
                </button>
              </div>
              <div className="sm:hidden">
                {tab === "operation" ? toggleWhyUsBox() : ""}
              </div>
              <div
                className={`pl-3 ${
                  tab === "partner" ? "border-l-2" : "opacity-50"
                }`}
              >
                <button
                  type="button"
                  onClick={(e) =>
                    tab === "partner" ? setTab("") : setTab("partner")
                  }
                >
                  <h3 className={`text-2xl font-arima`}>
                    {data.partner.title}
                  </h3>
                </button>
              </div>
              <div className="sm:hidden">
                {tab === "partner" ? toggleWhyUsBox() : ""}
              </div>
            </div>
            <div className="hidden sm:block sm:w-1/2 pt-2">
              {toggleWhyUsBox()}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyUs;

import React from "react";
import I18nContext from "../lib/i18n-context";

const FeaturedIn = () => {
  const { locale } = React.useContext(I18nContext);

  const data = locale.featuredIn;

  return (
    <section className="w-full text-darkblue">
      <div className="container max-w-screen-xl pt-14 md:pt-20 px-10 md:px-14 lg:px-20 sm:pb-6">
        <h2 className="font-arima text-3xl w-full border-b-2 pb-1 md:text-4xl font-light">
          {data.title}
        </h2>
        <div className="grid place-items-center place-content-center grid-cols-2 sm:grid-cols-4 gap-10 lg:gap-16 w-full py-16">
          <div>
            <a
              href="https://www.thedrum.com/news/2021/07/09/how-floradix-cracked-the-china-market-with-its-iron-tonic-marketing-push"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/assets/drum_navy.png" alt="The Drum company logo" />
            </a>
          </div>
          <div>
            <img
              src="/assets/info-markets.png"
              alt="Info Markets company logo"
            />
          </div>
          <div>
            <img
              src="/assets/vitafoods-featured.png"
              alt="Vitafoods company logo"
            />
          </div>
          <div>
            <img
              src="/assets/food-fi.png"
              alt="Food ingredients company logo"
            />
          </div>
          <div>
            <img src="/assets/cphl-worldwide.png" alt="CPhl worldwide logo" />
          </div>
          <div>
            <img src="/assets/wbr.png" alt="WBR logo" />
          </div>
          <div>
            <img src="/assets/etail.png" alt="eTail logo" />
          </div>
          <div>
            <a
              href="http://festivalofmedia.com/mmg/the-mmg-2021-winners/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/assets/MMG.png" alt="MMG awards logo" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturedIn;

import React from "react";
import Slider from "react-slick";
import I18nContext from "../lib/i18n-context";

const SimpleSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1600,
    autoplaySpeed: 7000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    pauseOnHover: true,
  };

  const { locale } = React.useContext(I18nContext);

  const data = locale.quotes;

  return (
    <div className="max-w-screen-md mx-auto w-full px-10 md:px-16 lg:px-4 xl:px-0">
      <Slider {...settings}>
        {data.testimonials.map((item, index) => (
          <div
            className={`space-y-4 focus:outline-none py-24 ${index === 0 ||
              (index === 1 && "py-16")} ${index === 2 && "py-20"} ${index ===
              3 && "py-28"}`}
              key={index}
          >
            <h2 className="text-left text-xs italic font-poppins font-light lg:text-sm">
              {data.title}
            </h2>
            <p className="font-arima sm:text-lg md:text-xl lg:text-2xl xl:text-3xl">
              {item.quote}
            </p>
            <div>
              <h5 className="font-arima">{item.role}</h5>
              <h6 className="font-poppins font-semibold">{item.company}</h6>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SimpleSlider;

import React from "react";
import I18nContext from "../lib/i18n-context";
import WorkCardsBlock from "../components/WorkCardsBlock";

const OurWork = () => {
  const i18nContext = React.useContext(I18nContext);
  const { locale } = i18nContext;

  const data = locale.ourWork;

  return (
    <section className="h-auto w-full bg-darkblue text-white ">
      <div className="container max-w-screen-xl flex flex-col justify-center text-left py-10 px-10 md:py-16 space-y-12 lg:px-20">
        <div className="space-y-4">
          <div className="font-arima text-3xl sm:text-4xl lg:text-5xl font-light">
            {data.title}
          </div>
          <p className="font-poppins font-light text-sm md:text-lg">
            {data.description}
          </p>
        </div>
        <WorkCardsBlock data={data} />
      </div>
    </section>
  );
};

export default OurWork;

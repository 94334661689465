import React from 'react'
import NavBar from './nav'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import I18nContext from '../lib/i18n-context'

const AppHeader = ({ setShowModal, videoRef }) => {
    const breakpoints = useBreakpoint()

    // i18n
    const i18nContext = React.useContext(I18nContext)
    const { locale } = i18nContext

    const data = locale.homeBanner

    return (
        <header>
            <NavBar logo={'/assets/main-logo.svg'} navClass={'blue-navbar'} svgColour="#fff" />
            {/* HEADER */}
            <section className="relative text-center text-white font-arima flex flex-col items-center justify-center h-screen overflow-hidden">
                <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-light max-w-[250px] sm:max-w-none">
                    {data.title}
                </h1>
                <div className="absolute bottom-0 mb-8 space-y-4">
                    <button
                        onClick={() => {
                            setShowModal(true);
                            if (videoRef.current) {
                                videoRef.current.pause();
                            }
                        }}
                        type="button"
                        className="font-poppins italic text-sm lg:text-lg xl:text-xl font-light rounded-xl shadow-2xl drop-shadow-2xl px-8 sm:px-6 md:px-8 lg:px-12 py-3 bg-darkblue border border-white hover:bg-gray-500 text-white mb-16 md:mb-24 hover:bg-opacity-25 bg-opacity-50 transiton duration-300 ease-in-out"
                    >
                        {data.buttonTitle}
                    </button>
                    <h5 className="text-md sm:text-lg md:text-xl lg:text-2xl font-light">
                        {data.slogan}
                    </h5>
                    <div>
                        <a href="#main">
                            <svg
                                className="mx-auto h-4"
                                xmlns="http://www.w3.org/2000/svg"
                                width="38.133"
                                height="21.895"
                                viewBox="0 0 38.133 21.895"
                            >
                                <path
                                    id="Path_751"
                                    data-name="Path 751"
                                    d="M809.7,852.977l17.652,17.652L845,852.977"
                                    transform="translate(-808.281 -851.562)"
                                    fill="none"
                                    stroke="#fff"
                                    strokeWidth="4"
                                />
                            </svg>
                        </a>
                    </div>
                </div>
                {/* VIDEO */}
                {breakpoints.md ? (
                    <img
                        src="/assets/about-us-design.jpg"
                        alt="QIVA main header"
                        style={{ zIndex: '-1' }}
                        className="absolute w-auto min-w-full min-h-full max-w-none"
                    />
                ) : (
                    <video
                        ref={videoRef}
                        preload={'auto'}
                        playsInline
                        muted
                        autoPlay
                        poster="/assets/video-poster.png"
                        loop
                        style={{ zIndex: '-1' }}
                        className="absolute w-auto min-w-full min-h-full max-w-none"
                    >
                        <source src="/assets/header-video-v2-mini.mp4" type="video/mp4" />
                    </video>
                )}
            </section>
        </header>
    )
}

export default AppHeader

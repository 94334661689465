import React from "react";
import WorkCard from "../components/our-work-card";

const WorkCardsBlock = ({ data, border }) => {
  return (
    <div className="grid md:grid-cols-3 lg:grid-cols-3 gap-4 space-y-8 md:space-y-0">
      <WorkCard
        animation={"fade-up"}
        path={"/iron-women"}
        logo={"/assets/drum-award.png"}
        logoClass={"w-1/2"}
        duration={"1500"}
        border={border ? "border-2" : ''}
        img={"/assets/iron-women-home.png"}
        alt={"display of iron lady home"}
        title={data.projectOne.title}
        desc={data.projectOne.description}
      />
      <WorkCard
        animation={"fade-up"}
        duration={"1300"}
        path={"/snack-revolution"}
        img={"/assets/snack-our-work.jpg"}
        border={border ? "border-2" : ''}
        alt={"display of grenade client work"}
        title={data.projectTwo.title}
        desc={data.projectTwo.description}
      />
      <WorkCard
        animation={"fade-up"}
        duration={"1100"}
        path={"/nandos"}
        img={"/assets/o2o-work.jpg"}
        border={border ? "border-2" : ''}
        alt={"display of Kleiner Fuch client work"}
        title={data.projectThree.title}
        desc={data.projectThree.description}
      />
    </div>
  );
};

export default WorkCardsBlock;
